<template>
    <aside class="bg-app-purple h-full w-full col-span-1 border-r-2 border-gray-500 py-3">
        <ul class="relative h-full ml-3">
            <li class="mb-5">
                <RouterLink :to="{ name: 'virtual-num' }">
                    <Icon name="home" class="text-gray-500" />
                </RouterLink>
            </li>
            <li class="mb-5">
                <RouterLink :to="{ name: 'virtual-num' }">
                    <Icon name="settings" class="text-gray-500"  />
                </RouterLink>
            </li>
            <li class="mb-5">
                <RouterLink :to="{ name: 'virtual-num' }">
                    <Icon name="chart-bar" class="text-gray-500" />
                </RouterLink>
            </li>
            <li class="mb-5">
                <RouterLink :to="{ name: 'virtual-num' }">
                    <Icon name="user" class="text-gray-500" />
                </RouterLink>
            </li>
            <li class="w-8 absolute top-full left-1/2 z-10" style="transform: translate(-50%, -100%)">
                <a href="/">
                    <img src="@/assets/images/header-logo.png" alt="Xlinx" class="w-full h-full">
                    <!-- <img src="@/assets/homepage/header-logo.png" alt="Logo" /> -->
                </a>
            </li>
        </ul>
    </aside>
</template>

<script>
    export default {
        name: "aside-section"
    }
</script>

<style scoped>

</style>
