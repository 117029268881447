<template>
    <div class="sidebar text-black dark:text-off-white grid grid-cols-9 border-0 border-r border-gray-300 dark:bg-black">

        <aside-section />

        <section class="col-span-8">
            <div class="main text-left">
                <ul class="font-inter">
                    <template v-if="v_flow1">
                        <ListItem
                            :title="$t('label.virtual-number.side-menu-bar.body.dashboard')"
                            mainClasses="flex itmes-center"
                            classes="inline-block flex-grow"
                            icon="contact"
                            iconClass="-mt-1"
                            @click.prevent="$router.push({name: 'virtual-dashboard'})"
                            :isActive = "route.name == 'virtual-dashboard'"
                        >
                            <template #meta>
                                <span></span>
                            </template>
                        </ListItem>
                        <ListItem
                                :title="$t('label.virtual-number.side-menu-bar.body.pricing-coverage')"
                                mainClasses="flex itmes-center"
                                classes="inline-block flex-grow"
                                icon="sidebar"
                                iconClass="-mt-1"
                                @click.prevent="$router.push({name: 'virtual-coverage'})"
                                :isActive = "route.name == 'virtual-coverage'"
                        ></ListItem>
                        <ListItem
                                :title="$t('label.virtual-number.side-menu-bar.body.did-numbers')"
                                mainClasses="flex itmes-center"
                                classes="inline-block flex-grow"
                                icon="appearance"
                                iconClass="-mt-1"
                                @click.prevent="$router.push({name: 'virtual-user-dids'})"
                                :isActive = "route.name == 'virtual-user-dids'"
                        ></ListItem>
                        <ListItem
                                :title="$t('label.virtual-number.side-menu-bar.body.trunks')"
                                mainClasses="flex itmes-center"
                                classes="inline-block flex-grow"
                                icon="sidebar"
                                iconClass="-mt-1"
                                @click.prevent="$router.push({name: 'virtual-trunks'})"
                                :isActive = "route.name == 'virtual-trunks'"
                        ></ListItem>
                        <ListItem
                                :title="$t('label.virtual-number.side-menu-bar.body.capacity')"
                                mainClasses="flex itmes-center"
                                classes="inline-block flex-grow"
                                icon="multilanguage"
                                iconClass="-mt-1"
                                @click.prevent="$router.push({name: 'virtual-capacity-pools'})"
                                :isActive = "route.name == 'virtual-capacity-pools'"
                        ></ListItem>
                        <ListItem
                                :title="$t('label.virtual-number.side-menu-bar.body.call-logs')"
                                mainClasses="flex itmes-center"
                                classes="inline-block flex-grow"
                                icon="sidebar"
                                iconClass="-mt-1"
                                @click.prevent="$router.push({name: 'virtual-call-logs'})"
                                :isActive = "route.name == 'virtual-call-logs'"
                        ></ListItem>
                        <ListItem
                                :title="$t('label.virtual-number.side-menu-bar.body.sms-logs')"
                                mainClasses="flex itmes-center"
                                classes="inline-block flex-grow"
                                icon="contact"
                                iconClass="-mt-1"
                                @click.prevent="$router.push({name: 'virtual-sms-logs'})"
                                :isActive = "route.name == 'virtual-sms-logs'"
                        ></ListItem>
                    </template>
                </ul>
            </div>
        </section>
    </div>
</template>

<script>
    import { useRoute } from 'vue-router'
    import { computed } from 'vue'
    import asideSection from '../aside-section'
    import ListItem from './Listitem'
    export default {
        name: "index",
        components:{
            asideSection,
            ListItem
        },
        setup(){
            const route = useRoute()
            const isDashboardPage = computed(() => {
                return ['virtual-number'].includes(route.name)
            })
            return{
                route,
                isDashboardPage,
                v_flow1: true,
            }
        }
    }
</script>

<style scoped>
.main ul > li{
    padding: 0.65rem 0.5rem;
    vertical-align: middle;
}

.sidebar .main ul > li:not(:first-of-type) {
    margin-top: 2px;
}
</style>
