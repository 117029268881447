<template>
  <Layout>
    <mainSection />
  </Layout>
</template>

<script>
import mainSection from '@/modules/virtualnum/components/home/main-section'
import Layout from '@/modules/virtualnum/components/home/Layout/Index'
import useVirtualConfig from '@/modules/virtualnum/composables/useVirtualConfig'
export default {
  name: 'Home',
  components: {
    mainSection,
    Layout
  },
  setup () {
    const getMainInfo = useVirtualConfig()
    return {
      getMainInfo
    }
  },
  onMounted () {
    this.getMainInfo()
  },
  created () {
    this.$i18n.locale = localStorage.getItem('user_lang')
  }
}
</script>
