<template>
    <li
            class="sidebar-link sidebar-list-dark"
            :class="mainClasses"
    >
        <a href="#"
           class="block"
           :class="classes"
        >
            <Icon
                    :name="icon"
                    class="inline w-7 h-7"
                    :color="isActive ? 'rgba(26, 179, 148, 1)' : '' "
                    :class="iconClass"
            />
            <span
                    class="inline ml-2.5 text-large leading-6 text-light-gray dark:text-off-white"
            >
                {{ title }}
            </span>
        </a>
        <slot name="meta"></slot>
    </li>
</template>

<script>
    export default {
        name: "Listitem",
        props: {
            title: {
                required: true,
                type: String
            },
            mainClasses: {
                required: false,
                type: String,
            },
            classes: {
                required: false,
                type: String
            },
            icon: {
                required: true,
                type: String
            },
            iconClass: {
                required: false,
                type: String
            },
            isActive: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        setup(){

        }
    }
</script>

<style scoped>

</style>
