<template>
  <div>
    <headerSection />
    <section class="main grid grid-cols-virtual">
      <sidebar />
      <main>
        <slot></slot>
      </main>
    </section>
  </div>
</template>

<script>
import sidebar from '@/modules/virtualnum/components/home/sidebar/index'
import headerSection from '../../../../common/components/header-section/index'
export default {
  name: 'Index',
  components: {
    sidebar,
    headerSection
  }
}
</script>

<style scoped lang="scss">
.sidebar {
  .main {
    ul {
      max-height: 100%;
      height: 100%;
      & > li {
        padding: 0.65rem 0.5rem;
        vertical-align: middle;
        &:not(:first-of-type) {
          margin-top: 2px;
        }
        a {
          padding: 0 0.5rem;
        }
      }
    }
  }
}
</style>
